<template>
  <home-blocks-wrapper
    id="posts"
    :title="LL.the_world_of()"
    :highlight-title="LL.programming()"
    link-view-more="/posts"
  >
    <div class="flex flex-col md:flex-row gap-4">
      <div class="md:basis-3/4 space-y-8">
        <HomeBlocksPostsTrending :posts="trending" />
        <HomeBlocksPostsLatest :posts="latest" />
      </div>
      <div class="md:basis-1/4 space-y-8">
        <HomeBlocksAuthorsRanking :authors="authors" />
        <HomeBlocksTagsPopular :tags="tags" />
      </div>
    </div>
  </home-blocks-wrapper>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";
import type { TPostTrending, TPost, TTopicPopular, TTopAuthor } from "~/features/post";

type Props = {
  trending: TPostTrending[];
  latest: TPost[];
  tags: TTopicPopular[];
  authors: TTopAuthor[];
  questionsCount?: number;
};

const { LL } = typesafeI18n();

defineProps<Props>();
</script>
