export const defaultSeoOgImage: SeoMeta = Object.freeze({
  ogImage: '/images/common/thumbnail.png',
  ogImageAlt: 'KungFuTech',
  ogImageHeight: '1196',
  ogImageWidth: '2048',
  ogImageType: 'image/png',
})

export const mapPathPriority = Object.freeze({
  '/': 1,
  '/phong-van-pro/': 0.9,
  '/khoa-hoc/': 0.9,
  '/posts/': 0.9,
})
