import type { Translation } from '../i18n-types'
const data = {
  "search": "Tìm kiếm",
  "search_not_found": "Không tìm được nội dung với từ khoá {keywords}.",
  "search_not_found_try_again": "Vui lòng thử lại.",
  "course": "Khóa học",
  "community": "Cộng đồng",
  "view_details": "Xem chi tiết",
  "view_all": "Xem tất cả",
  "view_details_course": "Xem chi tiết khóa học",
  "view_more": "Xem thêm",
  "trendings": "Xu hướng",
  "category": "Thể loại",
  "all": "Tất cả",
  "home": "Trang chủ",
  "written_by": "Được viết bởi",
  "donate_for_team": "Donate cho tác giả",
  "filter": "Bộ lọc",
  "interview": "Phỏng vấn",
  "login": "Đăng nhập",
  "register": "Đăng ký",
  "basic_java_course": "Khóa học Java nền tảng",
  "javascript_course": "Khóa học Javascript",
  "basic_cloud_course": "Khóa học Cloud cơ bản",
  "spring_boot_course": "Khóa học Java Spring ",
  "angular_course": "Khóa học Angular",
  "basic_docker_course": "Khóa học Docker cơ bản",
  "foundation_c_course": "Khóa học nền tảng C",
  "react_native_course": "Khóa học React Native",
  "basic_go_course": "Khóa học Go cơ bản",
  "interview_questions": "Câu hỏi phỏng vấn",
  "home_quote": "Nơi công nghệ giúp bạn có một cuộc sống tốt đẹp hơn. Sứ mệnh của TechMely là giúp bạn điều đó.",
  "topic": "Chủ đề",
  "home_banner_description": "Nền tảng cơ bản, DOM, Fetching Data, Kiến thức nâng cao, dự án game, dự án thực tế - Tất cả đều có trong khoá học",
  "terms_and_service": "Điều khoản và Dịch vụ",
  "frequently_asked_questions": "Các câu hỏi thường gặp",
  "privacy_policy": "Chính sách bảo mật",
  "follow_us": "Theo dõi chúng tôi",
  "password": "Mật khẩu",
  "do_you_have_account": "Bạn chưa có tài khoản?",
  "input_password_please": "Mật khẩu tối thiểu 8 ký tự",
  "first_name": "Họ và tên đệm",
  "last_name": "Tên",
  "username": "Tên tài khoản",
  "phone_number": "Số điện thoại",
  "already_have_an_account": "Bạn đã có tài khoản?",
  "change_language": "Đổi ngôn ngữ",
  "theme_switcher": "Đổi theme",
  "challenging_every_interview_question": "Thách thức mọi câu hỏi phỏng vấn",
  "for_developers": "dành cho lập trình viên",
  "interview_pro_description": "Top {count} câu hỏi phỏng vấn {name}",
  "upgrade_account": "Nâng cấp tài khoản",
  "you_need_to_login_to_view": "Bạn cần đăng nhập để xem",
  "you_need_to_upgrade_your_account_to_view": "Bạn cần nâng cấp tài khoản để xem",
  "upgrade_account_description_1": "Nâng cấp tài khoản của bạn để mở khóa",
  "upgrade_account_description_2": "câu hỏi phỏng vấn và những ưu đãi",
  "choose_the_right_package": "Chọn gói phù hợp",
  "popular": "Phổ biến",
  "package": "Gói",
  "this_package_includes": "Gói này bao gồm",
  "using": "Đang sử dụng",
  "congratulation": "Chúc mừng bạn",
  "account_has_been_successfully_upgraded_to": "Tài khoản đã được nâng cấp thành công lên",
  "failure": "Thất bại",
  "payment_failed_please_try_again": "Thanh toán không thành công, vui lòng thử lại",
  "change_password": "Đổi mật khẩu",
  "new_password": "Mật khẩu mới",
  "old_password": "Mật khẩu cũ",
  "new_password_again": "Nhập lại mật khẩu mới",
  "submit": "Hoàn tất",
  "profile": "Thông tin cá nhân",
  "update_avatar": "Cập nhật ảnh đại diện",
  "there_are_no_posts_yet": "Không có bài viết nào",
  "article_details": "Chi tiết bài viết",
  "article": "Bài viết",
  "learn_more": "Tìm hiểu thêm",
  "seo_home_title": "KungFuTech - Nền tảng học lập trình hàng đầu Việt Nam",
  "seo_home_description": "KungfuTech là nơi giúp bạn học lập trình online, chia sẻ kiến thức lập trình bao gồm tài liệu học lập trình, các khóa học lập trình",
  "seo_policy_title": "Chính sách bảo mật",
  "seo_policy_description": "Xem chính sách bảo mật của chúng tôi và nó liên quan đến bạn như thế nào.",
  "seo_upgrade_account_title": "Nâng cấp tài khoản",
  "seo_upgrade_account_description": "Hãy nâng cấp tài khoản để mở khóa tất cả các câu hỏi phỏng vấn và khuyến mãi hấp dẫn",
  "seo_fpt_title": "FPT University",
  "seo_fpt_description": "Chia sẻ tài liệu học tập, kinh nghiệm và bài học chính khóa tại đại học FPT",
  "seo_login_title": "Đăng nhập",
  "seo_login_description": "Đăng nhập KungFuTech để trải nghiệm nhiều tính năng hấp dẫn hơn",
  "seo_register_title": "Đăng ký tài khoản trên KungfuTech",
  "seo_register_description": "Đăng ký trên KungFuTech để trải nghiệm nhiều tính năng hấp dẫn hơn",
  "seo_404_title": "Lôi 404 -  Không Tim Thấy Trang",
  "seo_404_description": "Rất tiếc - Trang của bạn không tồn tại trên KungFuTech!",
  "seo_programming_courses_title": "Khóa học lập trình",
  "seo_programming_courses_description": "Bộ sưu tập sách độc đáo, thú vị, dễ hiểu cho tất cả các bạn, từ con số không đến anh hùng",
  "seo_change_password_title": "Đổi mật khẩu",
  "seo_change_password_description": "Đổi mật khẩu trên Kungfutech",
  "seo_profile_title": "Thông tin của tôi",
  "seo_profile_description": "Hồ sơ của tôi trên Kungfutech",
  "slide_title_1": "Nền tảng học lập trình hàng đầu Việt Nam",
  "slide_description_1": "Làm chủ các khóa học dành cho người mới bắt đầu và những bạn mất gốc...",
  "slide_title_2": "Bí quyết thành công trong cuộc phỏng vấn lập trình",
  "slide_description_2": "Hơn 6000 câu hỏi phỏng vấn Full-Stack, Coding & System Design Interview giúp bạn sẵn sàng cho cuộc phỏng vấn lập trình",
  "slide_title_3": "Khám phá học tập mới mỗi ngày, từng bước tiến xa hơn",
  "slide_description_3": "Chia sẻ và khám phá các bài viết về công nghệ tới mọi người cùng chúng tôi.",
  "slide_title_4": "Học lập trình với miễn phí trên youtube TechMely",
  "slide_description_4": "Đăng ký kênh TechMely để cập nhật tin tức công nghệ, các khoá học miễn phí và vô vàn điều thú vị khác...",
  "discover_now": "Khám phá ngay",
  "input_your_email": "Nhập email hoặc username của bạn",
  "email_or_username": "Email hoặc username",
  "hint_password": "Mật khẩu tối thiểu 8 ký tự, ít nhất một chữ hoa, một chữ thường, một ký tự đặc biệt và một số",
  "logout": "Đăng xuất",
  "programming": "Lập trình",
  "latest": "Mới nhất",
  "question": "Câu hỏi",
  "loading": "Đang tải...",
  "suggest_question": "Gợi ý câu hỏi phỏng vấn",
  "edit_and_contribute_articles": "Chỉnh sửa và đóng góp bài viết",
  "answer": "Câu trả lời",
  "hide_answers": "Ẩn câu trả lời",
  "show_answer": "Xem câu trả lời",
  "edit_article": "Chỉnh sửa bài viết",
  "share_the_post_on_facebook": "Chia sẻ bài viết lên Facebook",
  "share_the_article_on_linkedin": "Chia sẻ bài viết lên Linkedin",
  "share_the_post_on_twitter": "Chia sẻ bài viết lên Twitter",
  "interview_description": "câu hỏi phỏng vấn Full-Stack, Coding & System Design Interview",
  "color_mode": "Chế độ màu sắc",
  "system": "Hệ thống",
  "light": "Sáng",
  "dark": "Tối",
  "logged_in_successfully": "Đăng nhập thành công!",
  "sign_up_success": "Đăng ký thành công!",
  "success": "Thành công",
  "change_password_successfully": "Đổi mật khẩu thành công!",
  "successfully_updated": "Cập nhật thành công",
  "successful_request_creation": "Tạo yêu cầu thành công",
  "create_order_description": "Bạn đã tạo thành công yêu cầu nâng cấp tài khoản của mình. Vui lòng thanh toán để hoàn tất việc nâng cấp tài khoản",
  "pay": "Thanh toán",
  "silver_package_description": "Mở khoá tất cả các câu hỏi trong vòng 1 tuần",
  "gold_package_description": "Mở khoá tất cả các câu hỏi trong vòng 1 tháng",
  "diamond_package_description": "Mở khoá tất cả các câu hỏi trong vòng 1 năm",
  "unlimited_question_viewing": "Mở khoá tất cả các câu hỏi",
  "receive_notifications_of_the_latest_questions": "Nhận thông báo về các câu hỏi mới nhất",
  "support_answering_personal_interview_questions": "Hỗ trợ giải đáp các câu hỏi phỏng vấn riêng",
  "2_weeks": "2 tuần",
  "2_months": "2 tháng",
  "1_year": "1 năm",
  "view_all_answers_in": "Xem tất cả các câu trả lời trong",
  "comment": "Bình luận",
  "previous_article": "Bài trước",
  "next_article": "Bài tiếp theo",
  "table_of_contents": "Nội dung bài viết",
  "minutes_of_reading": "phút đọc",
  "about_us": "Về chúng tôi",
  "recently_created": "Tạo gần đây",
  "newest": "Mới nhất",
  "kungfu_english_5000": "Chinh phục 5000 từ vựng cần thiết cùng KungfuTech!",
  "create_new_question_bank": "Tạo ngân hàng câu hỏi mới",
  "create_a_new_set_of_questions": "Tạo bộ câu hỏi mới",
  "update_set_of_questions": "Cập nhật bộ câu hỏi",
  "there_are_no_courses_yet": "Chưa có bộ học nào",
  "suggestions_for_you": "Gợi ý cho bạn",
  "search_for": "Tìm kiếm cho",
  "study_set": "Bộ học",
  "title": "Tiêu đề",
  "enter_a_title_for_example_networking_chapter_12": "Nhập vào tiêu đề, ví dụ như “Networking - Chương 12”",
  "please_enter_title": "Vui lòng nhập tiêu đề",
  "description": "Mô tả",
  "enter_a_description_for_this_questionnaire": "Nhập mô tả cho bộ học này",
  "please_enter_description": "Vui lòng nhập mô tả",
  "please_choose_category": "Vui lòng chọn thể loại",
  "add_categories": "Thêm thể loại",
  "add_questions": "Thêm câu hỏi",
  "create": "Tạo",
  "update": "Cập nhật",
  "no_results": "Không có kết quả",
  "add": "Thêm",
  "delete": "Xóa",
  "edit": "Chỉnh sửa",
  "not_be_empty": "Không được bỏ trống",
  "not_found": "Không tìm thấy",
  "course_name": "Khoá học {courseName}",
  "question_title": "Câu hỏi phỏng vấn {language}",
  "question_description": "Tổng hợp các câu hỏi phỏng vấn phổ biến có lời giải chi tiết cho {language}",
  "phone_number_incorect": "Số điện thoại không hợp lệ",
  "username_incorrect": "Tên tài khoản không hợp lệ",
  "exceeds_limit": "Vượt quá giới hạn {characters} ký tự",
  "search_or_enter_a_topic": "Tìm kiếm hoặc nhập chủ đề",
  "topic_suggestions": "Gợi ý chủ đề",
  "kungfu_posts_quote": "Những bí ẩn về lập trình vẫn đang chờ được khám phá",
  "create_articles": "Tạo bài viết",
  "follow": "Theo dõi",
  "followed": "Đã theo dõi",
  "user_followers": "người theo dõi",
  "user_following": "đang theo dõi",
  "introduce": "Giới thiệu",
  "new": "Mới nhất",
  "following": "Đang theo dõi",
  "bookmark": "Đã đánh dấu",
  "suggested_articles": "Bài viết được đề xuất",
  "article_by_the_same_author": "Bài viết cùng tác giả",
  "comments": "Bình luận",
  "enter_comment": "Nhập bình luận...",
  "publish": "Đăng bài",
  "create_new_article": "Tạo bài viết mới",
  "enter_post_title": "Nhập tiêu đề bài viết...",
  "enter_post_description": "Nhập mô tả nội dung bài viết...",
  "please_choose_topic": "Vui lòng chọn chủ đề ít nhất 1 chủ đề",
  "please_enter_content": "Vui lòng nhập nội dung",
  "thumbnail": "Ảnh thu nhỏ",
  "no_comments": "Chưa có bình luận nào",
  "popular_topic": "Chủ đề phổ biến",
  "you_need_to_login_to_action": "Bạn cần đăng nhập để thực hiện hành động",
  "complete_set": "trọn bộ",
  "need_public_email_github": "Bạn cần để public email trong profile github",
  "login_social": "Đăng nhập với",
  "or": "Hoặc",
  "you_already_have_account_with": "Email {email} cần được đăng nhập với {provider}. Vui lòng thử lại!",
  "label_check_interview": "Đánh dấu hoàn thành",
  "label_uncheck_interview": "Đã hoàn thành",
  "input_keyword_placeholder_interview": "Nhập từ khoá bạn muốn tìm kiếm...",
  "check_unlock_interview": "Mở khóa",
  "need_logout": "Bạn cần đăng xuất và đăng nhập lại để nâng cấp tài khoản có hiệu lực",
  "language": "Ngôn ngữ",
  "market": "Thị trường",
  "rank_account": "Bạn đang ở cấp độ",
  "interview_details": "Chi tiết câu hỏi phỏng vấn",
  "hint_old_password": "Mật khẩu cũ tối thiểu 8 ký tự, ít nhất một chữ hoa, một chữ thường, một ký tự đặc biệt và một số",
  "match_password": "Nhập lại mật khẩu mới phải trùng khớp với mật khẩu mới",
  "has_error": "Đã xảy ra lỗi!",
  "there_are_no_list_courses_yet": "Chưa có khóa học nào!",
  "pathway_to_becoming": "Lộ trình trở thành",
  "pathway_to_becoming_des": "Lộ trình đầy đủ để trở thành một {role} từ cơ bản đến nâng cao, bao gồm nhiều tài liệu chi tiết giúp cho các bạn học tập và làm việc hiệu quả.",
  "recommend_using": "Tôi khuyên dùng",
  "can_be_replaced_with": "Có thể thay thế",
  "can_be_learned_later": "Có thể để học sau",
  "not_recommend_using": "Tôi không khuyên dùng",
  "keep_studying": "Tiếp tục học tập",
  "subscribe_to_receive_updates": "Đăng ký nhận cập nhật",
  "unlocked": "Đã mở",
  "upgrade_to_unlock": "Nâng cấp tài khoản để mở",
  "need_upgrade_to_see_all_answer": "Cần nâng cấp để xem toàn bộ câu trả lời",
  "next_question": "Câu hỏi tiếp theo",
  "prev_question": "Câu hỏi trước",
  "publish_at": "Đăng vào",
  "complete_payment_warning": "Vui lòng thanh toán để hoàn tất nâng cấp tài khoản",
  "receive_ebook": "Nhận Ebook cẩm nang phỏng vấn",
  "download_ebook": "Tải xuống Ebook cẩm nang phỏng vấn",
  "need_upgrade_rank_ebook": "Bạn cần nâng cấp lên rank Gold trở lên để tải Ebook",
  "send": "Gửi",
  "reply": "Phản hồi",
  "you": "Bạn",
  "free": "Miễn phí",
  "goto_learn": "Vào học",
  "ranking_board": "Bảng xếp hạng",
  "interview_question_description": "câu hỏi phỏng vấn Full-Stack, Coding & System Design",
  "the_user": "Người dùng",
  "testimonial": "nói gì về chúng tôi",
  "course_title_basic_advanced": "Khoá học {course} từ cơ bản đến nâng cao",
  "students": "Học viên",
  "price_discount": "Giá ưu đãi",
  "ratings": "Đánh giá",
  "js_udemy_course_description": "Các khoá học xây dựng bài bản cung cấp kiến thức và kỹ năng đáp ứng nhu cầu của thị trường",
  "members": "Thành viên",
  "java_course_description": "Java là một trong những ngôn ngữ lập trình hướng đối tượng. Nó được sử dụng trong phát triển phần mềm, trang web, game hay ứng dụng trên các thiết bị di động. Cuốn Java được viết một cách bài bản, chi tiết từ những kiến thức nền tảng giành cho mọi đối tượng",
  "javascript_course_description": "Javascript là ngôn ngữ lập trình kịch bản (scripting language) cho client-side, sau này còn cho cả server-side (Nodejs). Javascript được sử dụng chủ yếu để nâng cao sự tương tác của người dùng với trang web. Nói cách khác, bạn có thể làm cho trang web trở nên sinh động và tăng tính tương tác hơn.",
  "react_course_description": "ReactJS là một thư viện JavaScript phổ biến và mạnh mẽ được phát triển bởi Facebook. Nó được sử dụng rộng rãi để xây dựng các ứng dụng web hiệu suất cao và giao diện người dùng tương tác. ReactJS tập trung vào việc xây dựng giao diện người dùng đáng tin cậy và dễ bảo trì bằng cách sử dụng các thành phần (components) tái sử dụng.",
  "c_plus_course_description": "C++ là một trong những ngôn ngữ lập trình hướng đối tượng. Nó được sử dụng trong phát triển phần mềm, trang web, game hay ứng dụng trên các thiết bị di động. Cuốn C++ được viết một cách bài bản, chi tiết từ những kiến thức nền tảng giành cho mọi đối tượng",
  "course_title": "Khoá học",
  "popular_title": "phổ biến",
  "social_network": "Mạng xã hội",
  "find_frontend_jobs": "Tìm việc Frontend",
  "find_backend_jobs": "Tìm việc Backend, Devops",
  "product_feedback": "Phản hồi sản phẩm",
  "testimonials_1": "Trang này cung cấp các khoá học lập trình hữu ích và các câu hỏi phỏng vấn cho lập trình viên giúp tôi nắm vững kiến thức và chuẩn bị tốt cho công việc. Ngoài ra, những bài viết chia sẻ về lập trình cũng rất bổ ích và thú vị",
  "testimonials_2": "Trang web này thực sự tuyệt vời! Các khoá học rất rõ ràng và dễ hiểu, giúp tôi cải thiện kỹ năng lập trình của mình mỗi ngày. Câu hỏi phỏng vấn cũng rất thú vị và hữu ích cho quá trình chuẩn bị cho cuộc phỏng vấn.",
  "testimonials_3": "Trang này là nguồn tài nguyên quý báu cho bất kỳ lập trình viên nào muốn nâng cao kỹ năng của mình. Các khoá học rất chi tiết và thú vị, giúp tôi hiểu sâu hơn về lập trình. Tôi rất hài lòng với trang web này.",
  "testimonials_4": "Trang web này là một nguồn tài nguyên tuyệt vời cho việc học lập trình. Các bài viết chia sẻ rất hữu ích và thú vị. Tôi đã học được rất nhiều điều từ trang này và sẽ tiếp tục theo dõi.",
  "testimonials_5": "Trang web này đã cung cấp cho tôi một trải nghiệm học tập tuyệt vời. Tôi thực sự thích cách cung cấp kiến thức trong các khoá học và các bài viết chia sẻ. Rất cảm ơn trang web này!",
  "testimonials_6": "Tôi rất ấn tượng với trang web này! Các khoá học rất chi tiết và dễ hiểu, giúp tôi nâng cao kỹ năng lập trình của mình một cách đáng kể. Những bài viết chia sẻ cũng rất thú vị và bổ ích.",
  "people_you_may_know": "Bạn có thể biết",
  "update_profile": "Cập nhật thông tin",
  "my_profile": "Trang cá nhân",
  "i_need_support": "Tôi cần hỗ trợ",
  "warning": "Cảnh bảo",
  "warning_change_password": "Tài khoản của bạn đăng nhập bằng {provider} nên bạn không thể đổi mật khẩu, nếu cần hỗ trợ vui lòng chọn phần \"Tôi cần hỗ trợ\"",
  "silver_package": "Gói bạc",
  "gold_package": "Gói vàng",
  "diamond_package": "Gói kim cương",
  "savings": "Tiết kiệm",
  "faq": "Câu hỏi thường gặp",
  "faq1": "Tôi có thể thanh toán qua những hình thức nào?",
  "aws1": "Bạn có thể thanh toán qua Thẻ quốc tế (Visa, Mastercard, JCB)",
  "aws2": "Thẻ nội địa (Vietcombank, MBBank, TPBank, BIDV, VietinBank, Agribank, Techcombank, VPBank, VIB, .... và rất nhiều ngân hàng khác)",
  "aws3": "Internet banking (DongA Bank, OCB, BIDV)",
  "aws4": "Nếu bạn không thể thanh toán những hình thức trên do bạn không có thẻ hoặc internet banking không hỗ trợ ngân hàng của bạn, bạn vui lòng liên hệ tới facebook của",
  "aws5": "Techmely, ",
  "aws6": "chúng tôi sẽ hỗ trợ bạn thanh toán một cách nhanh nhất có thể",
  "faq2": "Nếu tôi không thể thanh toán những hình thức trên thì tôi cần làm gì?",
  "no_advertisements": "Không hiển thị quảng cáo",
  "receive_interview_tutorial_videos": "Nhận video hướng dẫn phỏng vấn",
  "questions_not_blurred": "Câu hỏi không bị làm mờ",
  "suggest_post": "Gợi ý bài viết",
  "suggest_article": "Gợi ý bài viết liên quan",
  "dob": "Ngày sinh",
  "default": "Mặc định",
  "font_family": "Font chữ",
  "font_size": "Cỡ chữ",
  "learn_programming_with": "Học lập trình với",
  "words": "Từ",
  "congratulations": "Chúc mừng ",
  "upgrade_success": "Đã nâng cấp tài khoản thành công lên ",
  "entry_explain": "Các câu hỏi cấp độ này thường được hỏi về kiến thức cơ bản và lý thuyết lập trình để đánh giá khả năng hiểu biết cơ bản và tư duy lập trình",
  "junior_explain": "Các câu hỏi cấp độ này thường được hỏi với đối tượng đã có một số kinh nghiệm làm việc và có khả năng tự giải quyết vấn đề cơ bản",
  "middle_explain": "Các câu hỏi cấp độ này thường được hỏi với đối tượng có kinh nghiệm làm việc đáng kể và có khả năng tự lập trình các dự án phức tạp",
  "senior_explain": "Các câu hỏi cấp độ này thường được hỏi với đối tượng có nhiều năm kinh nghiệm và có khả năng lãnh đạo kỹ thuật trong các dự án",
  "expert_explain": "Các câu hỏi cấp độ này thường được hỏi với đối tượng là những chuyên gia hàng đầu, có khả năng định hình và ảnh hưởng đến hướng đi công nghệ của tổ chức",
  "oh_it_seems_you_are_lost": "Ôi, hình như bạn đi lạc rồi",
  "we_cant_find_the_address_you_are_trying_to_visit": "Chúng mình không tìm thấy địa chỉ bạn muốn truy cập.",
  "back_to_homepage": "Trở lại Trang chủ",
  "missions": "Nhiệm vụ",
  "rewards": "Phần thưởng",
  "do_the_task": "Thực hiện",
  "get_reward": "Nhận thưởng",
  "upgrade": "Nâng cấp",
  "the_world_of": "Thế giới",
  "seo_posts_title": "Thế Giới Lập Trình - Khám Phá Kiến Thức và Bí Ẩn Về Lập Trình",
  "seo_posts_description": "Tham gia hành trình khám phá Thế Giới Lập Trình - nơi tổng hợp những bài viết, kiến thức, và bí ẩn thú vị trong lĩnh vực lập trình dành cho mọi cấp độ từ cơ bản đến nâng cao"
} satisfies Translation
export default data