<template>
  <div class="flex flex-col min-h-screen">
    <slot name="app-before" />
    <div id="app-before"/>
    <LazyAuthModal />
    <div>
      <slot />
    </div>
    <slot name="app-after" />
    <div id="app-after"/>
  </div>
</template>
