<template>
  <section class="mt-4">
    <UCarousel
      ref="carouselRef"
      v-slot="{ item }"
      :items="items"
      :ui="{ item: 'basis-full' }"
      class="rounded-lg overflow-hidden"
    >
      <div class="h-[340px] md:h-[266px] rounded-lg w-full" :class="item.background">
        <div class="px-12">
          <div
            class="h-80 md:h-64 flex flex-col items-center lg:items-start justify-center lg:text-left"
          >
            <h1
              v-if="item.id === 1"
              class="text-2xl sm:text-[26px] leading-9 font-bold mb-5 text-black dark:text-white text-center"
            >
              {{ item.title }}
            </h1>
            <p
              v-else
              class="text-2xl sm:text-[26px] leading-9 font-bold mb-5 text-black dark:text-white text-center"
            >
              {{ item.title }}
            </p>
            <p
              class="text-sm sm:text-base leading-7 font-thin mb-4 text-gray-800 dark:text-gray-200 text-center"
            >
              {{ item.description }}
            </p>
            <div>
              <nuxt-link rel="noreferrer" :to="item.link" :target="item.target">
                <UButton
                  :color="item.color"
                  variant="outline"
                  trailing-icon="i-heroicons-chevron-right-20-solid"
                  size="md"
                >
                  {{ item.textButton }}
                </UButton>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </UCarousel>
  </section>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";

const { LL } = typesafeI18n();
const items = computed(() => [
  {
    id: 1,
    title: LL.value.slide_title_1(),
    description: LL.value.slide_description_1(),
    link: "/khoa-hoc",
    textButton: LL.value.discover_now(),
    target: "",
    color: "teal",
    preload: true,
    background: "bg-teal",
  },
  {
    id: 2,
    title: LL.value.slide_title_2(),
    description: LL.value.slide_description_2(),
    link: "/phong-van-pro/full-stack-web-and-mobile/java",
    textButton: LL.value.discover_now(),
    target: "_blank",
    color: "fuchsia",
    background: "bg-fuchsia",
  },
  {
    id: 3,
    title: LL.value.slide_title_3(),
    description: LL.value.slide_description_3(),
    link: "/posts",
    textButton: LL.value.discover_now(),
    color: "pink",
    background: "bg-pink",
  },
  {
    id: 4,
    title: LL.value.slide_title_4(),
    description: LL.value.slide_description_4(),
    link: "https://www.youtube.com/c/TechMely?sub_confirmation=1",
    textButton: LL.value.discover_now(),
    target: "_blank",
    color: "cyan",
    background: "bg-cyan",
  },
]);

const carouselRef = ref();

onMounted(() => {
  setInterval(() => {
    if (!carouselRef.value) return;

    if (carouselRef.value.page === carouselRef.value.pages) {
      return carouselRef.value.select(0);
    }

    carouselRef.value.next();
  }, 3000);
});
</script>
<style scoped>
.bg-teal {
  background: linear-gradient(
    90deg,
    theme("colors.teal.100") 0%,
    rgba(204, 251, 241, 0.05) 98.73%
  );
}
.bg-pink {
  background: linear-gradient(
    90deg,
    theme("colors.pink.100") 0%,
    rgba(254, 226, 226, 0.05) 98.73%
  );
}
.bg-fuchsia {
  background: linear-gradient(
    90deg,
    theme("colors.fuchsia.100") 0%,
    rgba(250, 250, 250, 0.05) 98.73%
  );
}
.bg-cyan {
  background: linear-gradient(
    90deg,
    theme("colors.cyan.100") 0%,
    rgba(250, 250, 250, 0.05) 98.73%
  );
}
.dark {
  .bg-teal {
    background: linear-gradient(
      90deg,
      theme("colors.teal.900") 0%,
      rgba(204, 251, 241, 0.05) 98.73%
    ) !important;
  }
  .bg-pink {
    background: linear-gradient(
      90deg,
      theme("colors.pink.900") 0%,
      rgba(254, 226, 226, 0.05) 98.73%
    ) !important;
  }
  .bg-fuchsia {
    background: linear-gradient(
      90deg,
      theme("colors.fuchsia.900") 0%,
      rgba(250, 250, 250, 0.05) 98.73%
    ) !important;
  }
  .bg-cyan {
    background: linear-gradient(
      90deg,
      theme("colors.cyan.900") 0%,
      rgba(250, 250, 250, 0.05) 98.73%
    ) !important;
  }
}
</style>
