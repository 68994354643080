import type { Translation } from '../i18n-types'
const data = {
  "search": "Search",
  "search_not_found": "Couldn't find any contents relate to keywords {keywords}.",
  "search_not_found_try_again": "Please try again.",
  "course": "Course",
  "community": "Community",
  "view_details": "View details",
  "view_all": "View all",
  "view_details_course": "View details course",
  "view_more": "View more",
  "trendings": "Trendings",
  "category": "Category",
  "all": "All",
  "home": "Home",
  "written_by": "Written by",
  "donate_for_team": "Donte to the author",
  "filter": "Filter",
  "interview": "Interview",
  "login": "Login",
  "register": "Register",
  "basic_java_course": "Basic Java Course",
  "javascript_course": "Javascript Course",
  "basic_cloud_course": "Basic Cloud Course",
  "spring_boot_course": "Java Spring Course",
  "angular_course": "Angular Course",
  "basic_docker_course": "Basic Docker Course",
  "foundation_c_course": "Foundation C course",
  "react_native_course": "React Native Course",
  "basic_go_course": "Basic Go Course",
  "interview_questions": "Interview questions",
  "home_quote": "Technology helps you have a better life. TechMely's mission is to help you with that.",
  "topic": "Topic",
  "home_banner_description": "Basic foundation, DOM, Fetching Data, Advanced knowledge, game projects, real projects - All in the course",
  "terms_and_service": "Terms and Service",
  "frequently_asked_questions": "Frequently asked questions",
  "privacy_policy": "Privacy Policy",
  "follow_us": "Follow Us",
  "password": "Password",
  "do_you_have_account": "Do you have account?",
  "input_password_please": "Password minimum 8 characters",
  "first_name": "First name",
  "last_name": "Last name",
  "username": "Username",
  "phone_number": "Phone number",
  "already_have_an_account": "Do you already have an account?",
  "change_language": "Change language",
  "theme_switcher": "Change theme",
  "challenging_every_interview_question": "Challenging every interview question",
  "for_developers": "for developers",
  "interview_pro_description": "Top {count} {name} interview questions",
  "upgrade_account": "Upgrade account",
  "you_need_to_login_to_view": "You need to login to view",
  "you_need_to_upgrade_your_account_to_view": "You need to upgrade your account to view",
  "upgrade_account_description_1": "Upgrade your account to unlock",
  "upgrade_account_description_2": "interview questions and offers",
  "choose_the_right_package": "Choose the right package",
  "popular": "Popular",
  "package": "Package",
  "this_package_includes": "This package includes",
  "using": "Using",
  "congratulation": "Congratulation",
  "account_has_been_successfully_upgraded_to": "Account has been successfully upgraded to",
  "failure": "Failure",
  "payment_failed_please_try_again": "Payment failed, please try again",
  "change_password": "Change password",
  "new_password": "New password",
  "old_password": "Old password",
  "new_password_again": "Enter a new password",
  "submit": "Submit",
  "profile": "Profile",
  "update_avatar": "Update avatar",
  "there_are_no_posts_yet": "There are no posts yet",
  "article_details": "Article details",
  "article": "Article",
  "learn_more": "Learn more",
  "seo_home_title": "KungFuTech - Leading programming learning platform",
  "seo_home_description": "KungfuTech was a place help you learn programme, share the knowledge about Software Engineer domain, include documents and video courses",
  "seo_policy_title": "Privacy Policy",
  "seo_policy_description": "See our privacy policy and how it relates to you.",
  "seo_upgrade_account_title": "Upgrade account",
  "seo_upgrade_account_description": "Let's upgrade the account to open the lock, including ~10000 interview questions and exciting promotions",
  "seo_fpt_title": "FPT University",
  "seo_fpt_description": "Share the study documents, experiences and lessons key in FPT university",
  "seo_login_title": "Login",
  "seo_login_description": "Login on KungFuTech to experience more attractive features",
  "seo_register_title": "Register an account on KungfuTech",
  "seo_register_description": "Register on KungFuTech to experience more attractive features",
  "seo_404_title": "404 - Page not found",
  "seo_404_description": "Oops - Your page does not exist on the KungFuTech!",
  "seo_programming_courses_title": "Programming Courses",
  "seo_programming_courses_description": "The unique, interesting book collections, are easy to understand for all of you, from zero to hero",
  "seo_change_password_title": "Change password",
  "seo_change_password_description": "Change password on Kungfutech",
  "seo_profile_title": "My profile",
  "seo_profile_description": "My profile on Kungfutech",
  "slide_title_1": "Vietnam's leading programming learning platform",
  "slide_description_1": "Master courses for beginners and those who have lost their roots...",
  "slide_title_2": "The key to success in programming interview",
  "slide_description_2": "Over 6000 Full-Stack, Coding & System Design Interview questions to prepare you for programming interviews",
  "slide_title_3": "Explore new learning every day, step by step to advance further.",
  "slide_description_3": "Share and discover technology articles with everyone alongside us.",
  "slide_title_4": "Learn code for free on the TechMely YouTube channel.",
  "slide_description_4": "Subscribe to TechMely channel to stay updated on technology news, free courses, and a plethora of other interesting things...",
  "discover_now": "Discover now",
  "input_your_email": "Input your email or username",
  "email_or_username": "Email or username",
  "hint_password": "Password must be at least 8 characters, at least one uppercase letter, one lowercase letter, one special character and a number",
  "logout": "Logout",
  "programming": "Programming",
  "latest": "Latest",
  "question": "Question",
  "loading": "Loading...",
  "suggest_question": "Suggest question interview",
  "edit_and_contribute_articles": "Edit and contribute articles",
  "answer": "Answer",
  "hide_answers": "Hide Answers",
  "show_answer": "Show answer",
  "edit_article": "Edit article",
  "share_the_post_on_facebook": "Share the post on Facebook",
  "share_the_article_on_linkedin": "Share the article on Linkedin",
  "share_the_post_on_twitter": "Share the post on Twitter",
  "interview_description": "Full-Stack, Coding & System Design Interview questions",
  "color_mode": "Color mode",
  "system": "System",
  "light": "Light",
  "dark": "Dark",
  "logged_in_successfully": "Logged in successfully!",
  "sign_up_success": "Sign Up Success!",
  "success": "Success",
  "change_password_successfully": "Change password successfully!",
  "successfully_updated": "Successfully updated",
  "successful_request_creation": "Successful request creation",
  "create_order_description": "You have successfully created your account upgrade request. Please pay to complete the account upgrade",
  "pay": "Pay",
  "silver_package_description": "Unlock all questions within one week",
  "gold_package_description": "Unlock all questions within one month",
  "diamond_package_description": "Unlock all questions within one year",
  "unlimited_question_viewing": "Unlock all questions",
  "receive_notifications_of_the_latest_questions": "Receive notifications of the latest questions",
  "support_answering_personal_interview_questions": "Support answering personal interview questions",
  "2_weeks": "2 weeks",
  "2_months": "2 months",
  "1_year": "1 year",
  "view_all_answers_in": "View all answers in",
  "comment": "Comment",
  "previous_article": "Previous article",
  "next_article": "Next article",
  "table_of_contents": "Table of contents",
  "minutes_of_reading": "min read",
  "about_us": "About us",
  "recently_created": "Recently created",
  "newest": "Newest",
  "kungfu_english_5000": "Conquer 5000 essential vocabulary words with KungfuTech!",
  "create_new_question_bank": "Create new question bank",
  "create_a_new_set_of_questions": "Create a new set of questions",
  "update_set_of_questions": "Update set of questions",
  "there_are_no_courses_yet": "There are no courses yet",
  "suggestions_for_you": "Suggestions for you",
  "search_for": "Search for",
  "study_set": "Study set",
  "title": "Title",
  "enter_a_title_for_example_networking_chapter_12": "Enter a title, for example “Networking - Chapter 12”",
  "please_enter_title": "Please enter title",
  "description": "Description",
  "enter_a_description_for_this_questionnaire": "Enter a description for this questionnaire",
  "please_enter_description": "Please enter description",
  "please_choose_category": "Please choose category",
  "add_categories": "Add category",
  "add_questions": "Add question",
  "create": "Create",
  "update": "Update",
  "no_results": "No results",
  "add": "Add",
  "delete": "Delete",
  "edit": "Edit",
  "not_be_empty": "Not be empty",
  "not_found": "Not found",
  "course_name": "{courseName} course",
  "question_title": "{language} interview questions",
  "question_description": "Summary of common interview questions with detailed answers for {language}",
  "phone_number_incorect": "Phone number incorrect",
  "username_incorrect": "Username incorrect",
  "exceeds_limit": "Exceeds limit {characters} characters",
  "search_or_enter_a_topic": "Search or enter a topic",
  "topic_suggestions": "Topic suggestions",
  "kungfu_posts_quote": "The mysteries of technology are still waiting to be uncovered.",
  "create_articles": "Create article",
  "follow": "Follow",
  "followed": "Followed",
  "user_followers": "followers",
  "user_following": "followings",
  "introduce": "Introduce",
  "new": "Newest",
  "following": "Following",
  "bookmark": "Bookmark",
  "suggested_articles": "Suggested articles",
  "article_by_the_same_author": "Article by the same author",
  "comments": "Comments",
  "enter_comment": "Enter comment...",
  "publish": "Publish",
  "create_new_article": "Create new article",
  "enter_post_title": "Enter post title...",
  "enter_post_description": "Enter a description of the article content...",
  "please_choose_topic": "Please choose topic at least 1 topics",
  "please_enter_content": "Please enter content",
  "thumbnail": "Thumbnail photo",
  "no_comments": "No comments yet",
  "popular_topic": "Popular topic",
  "you_need_to_login_to_action": "You need to login to action",
  "complete_set": "complete set",
  "need_public_email_github": "You need to make your email public in your github profile",
  "login_social": "Login with",
  "or": "Or",
  "you_already_have_account_with": "You already have an account with {email} that needs to be logged in with {provider}. Please try again!",
  "label_check_interview": "Mark as completed",
  "label_uncheck_interview": "Completed",
  "input_keyword_placeholder_interview": "Enter the keyword you want to search for...",
  "check_unlock_interview": "Unlock",
  "need_logout": "You need to log out and log back in for the account upgrade to take effect",
  "language": "Language",
  "market": "Market",
  "rank_account": "You are at level",
  "interview_details": "Interview details",
  "hint_old_password": "Old password must be at least 8 characters, at least one uppercase letter, one lowercase letter, one special character and a number",
  "match_password": "The re-entered new password must match the new password.",
  "has_error": "An error has occurred!",
  "there_are_no_list_courses_yet": "There are no list courses yet!",
  "pathway_to_becoming": "Pathway to becoming",
  "pathway_to_becoming_des": "The complete pathway to becoming a proficient {role}, from basic to advanced level, including detailed resources to aid effective learning and work.",
  "recommend_using": "I recommend using",
  "can_be_replaced_with": "Can be replaced with",
  "can_be_learned_later": "Can be learned later",
  "not_recommend_using": "I do not recommend using",
  "keep_studying": "Keep studying",
  "subscribe_to_receive_updates": "Subscribe to receive updates",
  "unlocked": "Unlocked",
  "upgrade_to_unlock": "Upgrade account to unlock",
  "need_upgrade_to_see_all_answer": "You need to upgrade to see full answers",
  "next_question": "Next question",
  "prev_question": "Previous question",
  "publish_at": "Published at",
  "complete_payment_warning": "Please make a payment to complete the account upgrade",
  "receive_ebook": "Receive Interview Handbook Ebook",
  "download_ebook": "Download Interview Handbook",
  "need_upgrade_rank_ebook": "You need to upgrade to Gold rank or higher to download the Ebook.",
  "send": "Send",
  "reply": "Reply",
  "you": "You",
  "free": "Free",
  "goto_learn": "Go to learn",
  "ranking_board": "Ranking Board",
  "interview_question_description": "Full-Stack, Coding & System Design Interview questions",
  "the_user": "User",
  "testimonial": "Testimonial",
  "course_title_basic_advanced": "{course} course from basics to advanced",
  "students": "Students",
  "price_discount": "Discounted price",
  "ratings": "Ratings",
  "js_udemy_course_description": "Well-structured courses provide knowledge and skills that meet market demands",
  "members": "Members",
  "java_course_description": "The course is designed to take beginners through the fundamentals of Java programming to advanced concepts, enabling them to become proficient Java developers",
  "javascript_course_description": "The course is a dynamic and comprehensive guide designed to transform beginners into proficient JavaScript developers",
  "react_course_description": "The course offers a structured path from foundational concepts to advanced techniques in building scalable web applications",
  "c_plus_course_description": "The course is a meticulously crafted educational journey that takes students from the basics of C++ programming to advanced concepts, preparing them for real-world software development challenges",
  "course_title": "Courses",
  "popular_title": "popular",
  "social_network": "Social network",
  "find_frontend_jobs": "Find Frontend Jobs",
  "find_backend_jobs": "Find Backend, DevOps Jobs",
  "product_feedback": "Product Feedback",
  "testimonials_1": "This website provides useful programming courses and interview questions for programmers, helping me grasp knowledge and prepare well for the job. Additionally, the programming-related articles are very informative and interesting.",
  "testimonials_2": "This website is truly fantastic! The courses are very clear and understandable, helping me improve my programming skills every day. The interview questions are also very interesting and useful for the interview preparation process.",
  "testimonials_3": "This website is a valuable resource for any programmer looking to enhance their skills. The courses are very detailed and interesting, helping me understand programming more deeply. I am very satisfied with this website.",
  "testimonials_4": "This website is a great resource for learning programming. The shared articles are very helpful and interesting. I have learned a lot from this website and will continue to follow it.",
  "testimonials_5": "This website has provided me with a great learning experience. I really like how knowledge is delivered in the courses and the shared articles. I am very grateful for this website!",
  "testimonials_6": "I am very impressed with this website! The courses are very detailed and understandable, helping me significantly improve my programming skills. The shared articles are also very interesting and helpful.",
  "people_you_may_know": "People you may know",
  "update_profile": "Update profile",
  "my_profile": "My profile",
  "i_need_support": "I need support",
  "warning": "Warning",
  "warning_change_password": "Your account is logged in with {provider} so you cannot change your password. If you need support, please select \"I need support\"",
  "silver_package": "Silver Package",
  "gold_package": "Gold Package",
  "diamond_package": "Diamond Package",
  "savings": "Savings",
  "faq": "Frequently Asked Questions",
  "faq1": "What payment methods can I use?",
  "aws1": "You can pay with international cards (Visa, Mastercard, JCB).",
  "aws2": "Domestic cards (Vietcombank, MBBank, TPBank, BIDV, VietinBank, Agribank, Techcombank, VPBank, VIB, and many other banks).",
  "aws3": "Internet banking (DongA Bank, OCB, BIDV).",
  "aws4": "If you are unable to pay using the above methods because you don't have a card or your bank is not supported by internet banking, please contact",
  "aws5": "TechMely's",
  "aws6": "Facebook page. We will assist you in making the payment as quickly as possible.",
  "faq2": "What should I do if I can't pay using the above methods?",
  "no_advertisements": "No Advertisements",
  "receive_interview_tutorial_videos": "Receive interview tutorial videos",
  "questions_not_blurred": "Questions not blurred",
  "suggest_post": "Suggest post",
  "suggest_article": "Suggest article interview",
  "dob": "Date of birth",
  "default": "Default",
  "font_family": "Font family",
  "font_size": "Font size",
  "learn_programming_with": "Learn programming with",
  "words": "Words",
  "congratulations": "Congratulations",
  "upgrade_success": "Successfully upgraded the account to",
  "entry_explain": "Questions at this level are usually about basic knowledge and programming theory to assess fundamental understanding and programming thinking.",
  "junior_explain": "Questions at this level are typically asked of individuals with some work experience and the ability to solve basic problems on their own.",
  "middle_explain": "Questions at this level are usually asked of individuals with significant work experience and the ability to independently program complex projects.",
  "senior_explain": "Questions at this level are typically asked of individuals with many years of experience and the ability to lead technical aspects of projects.",
  "expert_explain": "Questions at this level are typically asked of top experts who have the ability to shape and influence the technological direction of the organization",
  "oh_it_seems_you_are_lost": "Oh, it seems you are lost",
  "we_cant_find_the_address_you_are_trying_to_visit": "We can't find the address you are trying to visit.",
  "back_to_homepage": "Back to Homepage",
  "missions": "Missions",
  "rewards": "Rewards",
  "do_the_task": "Do the task",
  "get_reward": "Get Reward",
  "upgrade": "Upgrade",
  "the_world_of": "The world of",
  "seo_posts_title": "The World of Programming - Uncover Knowledge and Mysteries of Coding"
} satisfies Translation
export default data